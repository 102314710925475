import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import qs from 'qs'
import i18n from './i18n/i18n'
import moment from 'moment'
import install from '@/components/common/index.js'
import './theme/index.css'
import './icon/iconfont.css'
import VueCodeMirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

Vue.use(VueCodeMirror)
Vue.use(install)

const paramsMap = {} // 地址栏参数
const params = (decodeURIComponent(location.href)).split('?')[1]
if (params) {
    params.split('&').forEach(item => {
        const strs = item.split('=')
        paramsMap[strs[0]] = strs[1]
    })
}
if(paramsMap.token){
    localStorage.setItem('token',paramsMap.token);
}
moment.locale('zh-cn') //设置语言 或 moment.lang('zh-cn')

Vue.prototype.$moment = moment//赋值使用

Vue.config.productionTip = false

//使用vue-axios，这样才可以全局使用this.axios调用
Vue.use(VueAxios, axios)

// axios.defaults.baseURL = 'http://iot.dbapi.v3.textile-saas.huansi.net'

axios.defaults.headers = { 'content-Type': 'application/x-www-form-urlencoded' }
//全局拦截post请求的参数，用qs序列化
axios.interceptors.request.use(config => {
    if (config.method === 'post' && config.headers['content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = qs.stringify(config.data, { indices: false })
    } 
    if (config.headers['content-type'] === 'application/json') {
        config.data = qs.stringify(config.data, { indices: false })
    }
    //拦截所有请求，添加登陆用户的token
    const iotToken = paramsMap['token']
    //const iotToken = localStorage.getItem('token')
    if (iotToken) {
        config.headers.authorization = iotToken
    }else {
        const localToken = localStorage.getItem('token');
        if(localToken){
            config.headers.authorization = localToken
        }
    }
    return config
})


//后台用户登陆信息校验不成功就跳转到登录页面
axios.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response && error.response.status == '401') {
        //不是api请求测试的请求，就跳转IOT登录页
        if (!error.response.config.url.startsWith('http://')) {
            router.push('/login')
            window.top.postMessage('401')
        } else {
            return Promise.reject(error)
        }
    } else {
        return Promise.reject(error)
    }
})

// //过滤器
Vue.filter('dateFormat', function (originVal) {
    const dt = new Date(originVal)

    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')

    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})


new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
