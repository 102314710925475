<template>
  <div class="home">
    <home-header></home-header>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import homeHeader from '@/components/header/homeHeader.vue'

export default {
  name: 'Home',
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getMode() {
      this.axios.post("/apiConfig/mode").then((response) => {
        this.$store.commit('setMode', response.data)
      }).catch((error) => {

      })
    }

  },
  components: {homeHeader},
  created() {
    this.getMode()

  }
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  .iconfont {
    color: #fff;
    font-size: 20px !important;
    font-weight: 700;
    padding-right: 3px;
  }
  .main {
    background-color: #fff;
    padding: 20px;
    flex: 1;
    overflow: auto;
  }
}
</style>
