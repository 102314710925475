<template>
  <div class="form">
    <span class="label">{{ (nullable ? '' : '*') + label }}</span>
    <div class="preffix" v-if="preffix != ''">{{ preffix }}</div>
    <input class="input" :value="value" @input="handleInput" :style="'width: ' + width" :placeholder="placeholder"/>
  </div>
</template>
<script>
export default {
  name: 'myInput',
  props: {
    value: {
      type: String | Number,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    nullable: {
      type: Boolean,
      default: true
    },
    preffix: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '200px'
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #ccc;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ccc;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ccc;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #ccc;
}

.form {
  display: inline-flex;
  height: 40px;
  line-height: 40px;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: Consolas, Arial, monospace !important;
  white-space: nowrap;

  .label {
    display: inline-block;
    background-color: #f5f7fa;
    padding: 0 10px;
    color: #909399;
    border: 1px solid #dcdfe6;
    border-right: none;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    height: 40px;
  }

  .preffix {
    display: inline-block;
    height: 40px;
    background-color: #f5f7fa;
    border: 1px solid #dcdfe6;
    vertical-align: top;
    padding: 0 3px;
    border-right-width: 0px;

  }

  .input {
    display: inline-block;
    padding: 0 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #dcdfe6;
    outline: none;
    border-radius: 0 4px 4px 0;
    font-family: Consolas, Arial, monospace;
    transition: all .2s;
    &:focus:not(:hover) {
      border-color: #c13232;
    }
    &:hover {
      border-color: #c0c4cc;
    }
  }
}
</style>