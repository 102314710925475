<template>
    <div class="process-api">
        <div>
            <ul class="action-buttons">
                <li>
                    <el-button icon="el-icon-d-arrow-left" type="info" plain @click="$router.go(-1)" size="small">{{
                        $t('m.back') }}
                    </el-button>
                </li>
            </ul>
        </div>

        <el-tabs tab-position="top" style="" type="border-card">
            <el-tab-pane :label="$t('m.basic_conf')">
                <div class="search">
                    <el-form label-width="120px">
                        <el-form-item :label="$t('m.basic_info')">
                            <my-input :label="$t('m.name')" :nullable="false" v-model="apiConfig.name"></my-input>
                            <my-input :label="$t('m.path')" v-model="apiConfig.path" :preffix="`http://${address}/`"
                                :nullable="false" width="400px"></my-input>
                            <my-select v-model="apiConfig.groupId" :options="groups" :label="$t('m.api_group')"
                                option_label="name" option_value="id" :nullable="false"></my-select>
                            <my-input :label="$t('m.note')" v-model="apiConfig.note" width="500px"></my-input>
                        </el-form-item>
                        <el-form-item label="定时器">
                            <codemirror class="time-code" :options="timerCodeOptions" @ready="onTimerCodeReady"
                                @blur="onTimerCodeChange" @inputRead="onTimerCodeChange"/>
                        </el-form-item>
                        <el-form-item label="Content-Type">
                            <el-select v-model="apiConfig.contentType" style="width:300px">
                            <el-option v-for="item in types" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('m.access')">
                            <el-radio-group v-model="apiConfig.previlege">
                                <el-radio :label="1">{{ $t('m.public') }}</el-radio>
                                <el-radio :label="0">{{ $t('m.private') }}</el-radio>
                            </el-radio-group>
                            <el-tooltip placement="top-start" effect="dark">
                                <div slot="content">
                                    {{ $t('m.access_tip') }}
                                </div>
                                <i class="el-icon-info tip"></i>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item :label="$t('m.ginseng_in')">
                            <div v-for="(item, index) in apiConfig.params"
                                style="margin-bottom:5px;display: flex;align-items:center">
                                <el-input v-model="item.name" placeholder="*参数名称"
                                    style="width:400px;margin-right:5px"></el-input>
                                <el-select v-model="item.type" placeholder="*数据类型" style="margin-right:5px">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-input v-model="item.note" placeholder="参数说明"
                                    style="width:400px;margin-right:5px"></el-input>
                                <el-button @click="deleteRow(index)" circle type="danger" icon="el-icon-delete"
                                    size="mini"></el-button>
                            </div>
                            <el-button @click="addRow" icon="el-icon-plus" type="primary" circle size="mini"></el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('m.process_api_config')">
                <el-button type="primary" class="add-process-btn" icon="el-icon-plus" @click="handleAdd">{{
                    $t('m.add_atomic_api') }}</el-button>
                <el-table :data="apiList" border stripe max-height="700" width="100%">
                    <el-table-column label="apiId" prop="apiId" width="100px">
                        <template slot-scope="scope">
                            <span >{{ scope.row.apiId ? scope.row.apiId : scope.row.id}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.name')">
                        <template slot-scope="scope">
                            <span :title="scope.row.note">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column :label="$t('m.ginseng_in')">
                        <template slot-scope="scope">
                            <data-tag v-for="item in scope.row.inputParam" :name="item.name" :type="item.type"></data-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="sort" :label="$t('m.sort')" width="170px">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.sort" size="small" autocomplete="off" placeholder="请输入排序"
                                type="number"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.operation')" width="130px" v-if="!isDetail">
                        <template slot-scope="scope">
                            <el-button plain size="mini" type="warning" @click="handleEdit(scope.row)" circle>
                                <i class="el-icon-edit"></i>
                            </el-button>
                            <el-button plain size="mini" type="danger" @click="handleDelete(scope.$index)" circle>
                                <i class="el-icon-delete"></i>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <el-button v-if="!isDetail" @click="save" style="margin: 10px 0">{{ $t('m.save') }}</el-button>
        <ProcessDialog ref="ProcessDialogRef" v-if="processDialogShow" @addSuccess="addSuccess"
            @closeDialog="processDialogShow = false" />
    </div>
</template>

<script>
import group from "@/components/api/group";
import ProcessDialog from "./common/processDialog.vue"
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';

export default {
    name: "api",
    data() {
        return {
            address: '',
            processDialogShow: false,
            tableData: [],
            groups: [],
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            headers: {
                Authorization: localStorage.getItem('token')
            },
            groupFile: [],
            context: null,
            timerCodeOptions: {
                value: '',
                tabSize: 2,
                lint: true,
                styleActiveLine: true,
                lineNumbers: true,
                mode: 'text/shell',
                theme: 'idea',
                matchBrackets: true
            },
            timeCoder: null,
            // 基础配置
            apiConfig: {
                name: "",
                path: "",
                note: null,
                groupId: "",
                timer: '',
                previlege: 1,
                contentType: 'application/x-www-form-urlencoded',
                params: []
            },
            types: ['application/x-www-form-urlencoded', 'application/json'],
            // table列表
            apiList: [],
            editeIndex: null,
            isDetail: false,
            options: [
                { label: 'string', value: 'string' },
                { label: 'bigint', value: 'bigint' },
                { label: 'double', value: 'double' },
                { label: 'date', value: 'date' },
                { label: 'string 数组', value: 'Array<string>' },
                { label: 'bigint 数组', value: 'Array<bigint>' },
                { label: 'double 数组', value: 'Array<double>' },
                { label: 'date 数组', value: 'Array<date>' }
            ],
        }
    },
    components: { group, ProcessDialog },
    methods: {

        // 添加入参
        addRow() {
            this.apiConfig.params.push({ name: null, type: null, note: null })
        },
        // 删除入参行
        deleteRow(index) {
            this.apiConfig.params.splice(index, 1)
        },
        // 保存
        save() {
            if (!this.apiConfig.name || !this.apiConfig.path || !this.apiConfig.groupId) {
                this.$message.error("必填项未填")
                return
            }
            let obj = JSON.parse(JSON.stringify({
                apiConfig: this.apiConfig,
                apiList: this.apiList
            }))
            if(!obj.apiConfig.sqlList || obj.apiConfig.sqlList.length === 0){
               obj.apiConfig.sqlList = [{sqlText: '',  transformPlugin: null, transformPluginParams: null}]
            }
            obj.apiConfig.params = JSON.stringify(obj.apiConfig.params)
            if (obj.apiList.length > 0) {
                let isVal = this.isVal(obj.apiList)
                if (isVal) {
                    this.$message.warning("排序不能有重复")
                    return false
                }
                obj.apiList.forEach((item) => {
                    if(item.id){
                        item.apiId = item.id
                    }else {
                       item.id = ''
                    }
                    item.inputParam = JSON.stringify(item.inputParam)
                })
            }
            this.axios.post("/processApi/save", {
                apiConfig: obj.apiConfig,
                apiList: obj.apiList
            }, {
                headers: { 'content-Type': 'application/json' }
            }).then((res) => {
                if(res.data.success){
                   this.$message.success("保存成功")
                   this.getProcessDetail()
                 }else {
                    let msg = res.data.msg || '保存失败'
                    this.$message.error(msg)
                 }
            }).catch(() => { })
        },

        // 判断数组中是否含有相等的值
        isVal(arr) {
            let names = arr.map(item =>  parseInt(item["sort"]));
            let nameSet = new Set(names);
            if (nameSet.size == names.length) {
                return false
            } else {
                return true
            }
        },
        // 获取流程api详情
        getProcessDetail() {
            if (this.$route.query.id) {
                this.axios.get("/processApi/detail/" + this.$route.query.id).then((response) => {
                    this.apiConfig = response.data.apiConfig
                    this.apiConfig.params = this.apiConfig.params != '{}' ? JSON.parse(this.apiConfig.params) : []
                    this.timeCoder && this.timeCoder.setValue(this.apiConfig.timer)
                    let apiList = response.data.apiList
                    if (apiList.length > 0) {
                        apiList.forEach((item) => {
                            item.inputParam = item.inputParam != '{}' ? JSON.parse(item.params) : []
                        })
                    }
                    this.apiList = apiList
                }).catch(() => { })
            }
        },
        // 添加成功
        addSuccess(data) {
            if (this.editeIndex != null) {
                this.apiList[this.editeIndex] = data
                this.$set(this.apiList, 0, data)
            } else {
                 
                this.apiList.push(data)
            }
            this.processDialogShow = false
        },
        getAddress() {
            this.axios.post("/apiConfig/getIPPort").then((response) => {
                this.address = response.data
            }).catch((error) => {
                // this.$message.error("失败")
            })
        },
        // 添加流程api
        handleAdd() {
            this.editeIndex = null
            this.processDialogShow = true
            this.$nextTick(() => {
                this.$refs.ProcessDialogRef.addEdite('add', { apiList: this.apiList })
            })
        },
        getAllApis() {
            this.axios.post("/apiConfig/searchList").then((response) => {
                const list = response.data
                list.forEach(t => {
                    const inputParam = JSON.parse(t.inputParam)
                    t['in'] = inputParam
                    const outputParam = JSON.parse(t.outputParam)
                    t['out'] = outputParam
                })
                this.apiList = list
            }).catch(() => { })
        },
        getContext() {
            this.axios.post("/apiConfig/context").then((response) => {
                this.context = response.data
            }).catch(() => { })
        },
        handleDelete(index) {
            this.apiList.splice(index, 1)
            this.$message.success("删除成功")
        },
        handleEdit(row) {
            this.processDialogShow = true
            this.$nextTick(() => {
                this.$router.push({ path: '/api/edit', query: { id: row.id } });
            })
        },

        getAllGroups() {
            this.axios.post("/group/getAll/").then((response) => {
                this.groups = response.data || []
            }).catch((error) => {
            })
        },
        onTimerCodeReady(cm) {
            this.timeCoder = cm
            cm.setValue(this.apiConfig.timer)
            cm.setSize('100%', '100px')
        },
        onTimerCodeChange(cm) {
            this.apiConfig.timer = cm.getValue()
        }
    },

    created() {
        // this.getAllApis()
        this.getAllGroups()
        this.getContext()
        this.getAddress()
        this.getProcessDetail()
        if (this.$route.query.isDetail) {
            this.isDetail = this.$route.query.isDetail
        }
    }
}
</script>
  
<style scoped lang="scss">
.process-api {
    i {
        font-size: 14px;
    }

    /deep/input[type="number"]::-webkit-inner-spin-button,
    /deep/input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
    }

    .time-code {
        border: 1px solid #999;

        /deep/ .CodeMirror-line {
            font-family: 'Consolas', Helvetica, Arial, sans-serif !important;
            font-size: 18px !important;
            line-height: 20px;

            .cm-comment {
                font-family: 'Consolas', Helvetica, Arial, sans-serif !important;
                font-size: 18px !important;
                line-height: 20px;
            }
        }

        /deep/ .CodeMirror-linenumber {
            line-height: 20px;
        }
    }

    .circle {
        border-radius: 10px;
        font-size: 18px;
        font-weight: 700;
        /*background-color: #05566b;*/
        padding: 3px;
        color: #38be2e;
    }

    .offline {
        color: #737373;
    }

    .lock {
        color: #c10b0b;
    }

    .tag {
        margin-right: 5px;
    }

    ul.action-buttons {
        li {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .add-process-btn {
        margin-bottom: 20px;
    }

    .search {
        >* {
            margin: 0 10px 10px 0;
        }
    }
}
</style> 