<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogFormVisible" @closed="handleClose" width="40%">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="API名称" :label-width="formLabelWidth" prop="apiId">
                    <el-select v-model="form.apiId" placeholder="请选择API名称" @change="chageApi" style="width:100%">
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in apiNameList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
                    <el-input v-model="form.sort" autocomplete="off" placeholder="请输入排序"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSave">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
export default {
    data() {
        return {
            title: '添加',
            dialogFormVisible: false,
            form: {
                apiId: "",
                inputParam: {},
                sort: ''
            },
            timeCoder: null,
            formLabelWidth: '120px',
            rules: {
                apiId: [{ required: true, message: '请选择API名称', trigger: 'blur' }],
                sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
            },
            accessGinsengOptions: {
                value: '',
                tabSize: 2,
                lint: true,
                styleActiveLine: true,
                lineNumbers: true,
                mode: 'text/shell',
                theme: 'idea',
                matchBrackets: true
            },
            apiNameList: [],
            options: [
                { label: 'string', value: 'string' },
                { label: 'bigint', value: 'bigint' },
                { label: 'double', value: 'double' },
                { label: 'date', value: 'date' },
                { label: 'string 数组', value: 'Array<string>' },
                { label: 'bigint 数组', value: 'Array<bigint>' },
                { label: 'double 数组', value: 'Array<double>' },
                { label: 'date 数组', value: 'Array<date>' }
            ],
        }
    },
    mounted() {
        this.getAllApis()
    },
    methods: {
        //切换
        chageApi(e) {
            let findApi = this.apiNameList.find((item) => {
                return item.id == e
            })
            this.form.apiId = findApi.id
            this.form.name = findApi.name
            this.form.inputParam = findApi.params = findApi.params != '{}' ? JSON.parse(findApi.params) : []
        },
        // 添加
        addEdite(type, row) {
            this.dialogFormVisible = true
            this.title = type == 'add' ? '新增流程API' : '编辑流程API'
            if (type == 'edite') {
                this.form.apiId = row.apiId;
                this.form.inputParam = row.inputParam;
                this.form.outputParam = row.outputParam;
                this.form.sort = row.sort;
                this.form.name = row.name;
            } else {
                this.form.id = ''
            }
        },

        handleClose() {
            this.$refs.ruleForm.resetFields()
            this.form = {
                apiId: "",
                inputParam: [],
                outputParam: [],
                sort: 0

            }
            this.$emit('closeDialog')
        },
        handleSave() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.$emit('addSuccess', this.form)
                    this.dialogFormVisible = false
                } else {
                    console.log('输入错误')
                }
            })
        },
        // 获取原子api名称下拉
        getAllApis() {
            this.axios.post("/apiConfig/searchList", {
                keyword: null,
                field: null,
                groupId: null,
                type: 0
            }).then((response) => {
                const list = response.data
                this.apiNameList = list
            }).catch(() => { })
        }
    }
}
</script>
<style lang="scss" scoped>
.code-mirror {
    border: 1px solid #999;

    /deep/ .CodeMirror-line {
        font-family: 'Consolas', Helvetica, Arial, sans-serif !important;
        font-size: 18px !important;
        line-height: 20px;

        .cm-comment {
            font-family: 'Consolas', Helvetica, Arial, sans-serif !important;
            font-size: 18px !important;
            line-height: 20px;
        }
    }

    /deep/ .CodeMirror-linenumber {
        line-height: 20px;
    }
}
</style>